<template>
  <div class="file-preview">
    <iframe :src="getFileData" style="width: 100%; height: 100%"></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getFileData", "getFileName"]),
  },
};
</script>

<style>
.file-preview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 1000;
}
</style>