var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "file-preview"
  }, [_c('iframe', {
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "src": _vm.getFileData
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }